.policy {
  font-size: 22px;
  line-height: 1.5;
  margin: 20px;
}

.policy h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.policy h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.policy p {
  font-size: 19px;
  margin-bottom: 10px;
}

.policy .contract {
  font-size: 13px;
  color: red;
  margin-bottom: 30px;
}

.share-information-strong {
  margin-right: 15px;
}

.policy-contact-us {
  color: green;
  font-size: 20px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .policy {
    font-size: 20px;
  }

  .policy h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .policy h2 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .policy p {
    font-size: 17px;
    margin-bottom: 8px;
  }

  .policy .contract {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .policy-contact-us {
    font-size: 18px;
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .policy {
    font-size: 18px;
    margin: 15px;
  }

  .policy h1 {
    font-size: 26px;
    margin-bottom: 12px;
  }

  .policy h2 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .policy p {
    font-size: 15px;
    margin-bottom: 6px;
  }

  .policy .contract {
    font-size: 11px;
    margin-bottom: 15px;
  }

  .policy-contact-us {
    font-size: 16px;
  }
}
