/* Styling for the event container */
.event-container {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f7f7f7;
  /* Set the height to be slightly smaller */
  height: 550px;
  /* Additional styles to center the content */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sory-container{
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f7f7f7;
  padding: 10px;
}
/* Event title */
.event-title {
  font-size: 32px;
  margin-bottom: 10px; /* Adjust margin between title and image */
}

/* Event dates and address */
.event-dates-address {
  font-size: 16px;
  color: #555;
  margin-bottom: 0; /* Reduce margin between title and date */
}

/* Event image */
.event-image {
  width: 100%;
  max-height: 250px; /* Adjust image height as needed */
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Event link */
.event-link {
  text-decoration: none;
  color: black;
}

/* Styling for the events container */
.events-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr)); /* Adjust minmax as needed */
  gap: 20px;
}

/* Additional adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .events-container {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 10px;
  }
}

/* For even smaller screens, e.g., mobile devices */
@media screen and (max-width: 480px) {
  .event-container {
    height: 500px; /* Adjust the height as needed for mobile view */
  }
  .event-title {
    font-size: 24px;
  }
  .event-image {
    max-height: 200px; /* Adjust image height for mobile view */
  }
  .event-dates-address {
    margin-bottom: 5px; /* Reduce the margin between title and address */
  }
  .sory-container{
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
    padding: 2px;
  }
  .sory-container h4{
    font-size: 12px;
  }
}
