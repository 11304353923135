.partenariat {
  font-size: 22px;
  line-height: 1.5;
  margin: 20px;
}

.partenariat h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.partenariat h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.partenariat p {
  font-size: 19px;
  margin-bottom: 10px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .partenariat {
    font-size: 20px;
    margin: 15px;
  }

  .partenariat h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .partenariat h2 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .partenariat p {
    font-size: 17px;
    margin-bottom: 8px;
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .partenariat {
    font-size: 18px;
    margin: 10px;
  }

  .partenariat h1 {
    font-size: 26px;
    margin-bottom: 12px;
  }

  .partenariat h2 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .partenariat p {
    font-size: 15px;
    margin-bottom: 6px;
  }
}
