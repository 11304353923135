.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}


.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}



.account-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: white;
}

.navbar p{
  color: rgb(238, 45, 45);
}

a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 25px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 10px;
}

h2 {
  margin-top: 0;
}

img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

img {
  width: 400px;
}

p {
  max-width: 400px;
  font-size: 12px;
}