.log-to-create{
    font-size: 15px;
    margin-top: 300px;
  }
  .log-to-create-green-text{
    font-size: 16px;
    color: green;
  }
  
  .log-to-create-form-button {
    margin-top: 80px;
    margin-bottom: 600px;
    display: block;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .log-to-create-form-button:hover {
    background-color: #0056b3;
  }

/* Styling for the event container */
.event-container {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f7f7f7;
  /* Set the height to be twice as big */
  height: 600px;
  /* Additional styles to center the content */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Event title */
.event-title {
  font-size: 32px;
  margin-bottom: 5px; /* Reduce margin between title and image */
}

/* Event dates and address */
.event-dates-address {
  font-size: 16px;
  color: #555;
  margin-bottom: 2px; /* Reduce margin between title and date */
}

/* Event image */
.event-image {
  width: 100%;
  max-height: 250px; /* Adjust image height as needed */
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Event link */
.event-link {
  text-decoration: none;
  color: black;
}

/* Styling for the events container */
.events-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr)); /* Adjust minmax as needed */
  gap: 20px;
}

/* Additional adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .events-container {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 10px;
  }
}

/* For even smaller screens, e.g., mobile devices */
@media screen and (max-width: 480px) {
  .event-container {
    height: 500px; /* Adjust the height as needed for mobile view */
  }
  .event-title {
    font-size: 24px;
  }
  .event-image {
    max-height: 200px; /* Adjust image height for mobile view */
  }
  .event-dates-address {
    margin-bottom: 0; /* Remove the margin between title and address */
  }
}
