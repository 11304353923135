.event-details-delete-button,
.event-details-share-button,
.event-details-save-button,
.event-details-sponsor-button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-bottom: 25px;
}

.event-details-delete-button:hover,
.event-details-share-button:hover,
.event-details-save-button:hover,
.event-details-sponsor-button:hover {
  background-color: #0056b3;
}

.heart-icon{
  margin-left: 150px;
}
.share-icon{
  margin-left: 150px;
}

.event-details-description {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 16px;
  white-space: pre-wrap;
}

.event-details-contact-email {
  font-size: 16px;
}

.facebook-share-button {
  margin-right: 15px;
}

.whatsapp-share-button {
  margin-right: 15px;
}

.linkedin-share-button {
  margin-right: 15px;
}

.email-share-button {
  margin-right: 15px;
}

.event-details-sponsor-button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #13d416;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-bottom: 30px;
}

.event-details-sponsor-button:hover {
  background-color: #0056b3;
}

.social-media-sponsor-icons {
  margin-top: 20px;
}

.social-media-sponsor-icons a,
.social-media-sponsor-icons p {
  display: inline-block;
  margin-right: 10px;
  color: #007bff;
  font-size: 40px;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.social-media-sponsor-icons a:hover,
.social-media-sponsor-icons p:hover {
  color: #0056b3;
}

.social-media-sponsor-icons .malievents-email {
  font-size: 25px;
}

/* Existing CSS styles */

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .event-details-delete-button,
  .event-details-share-button,
  .event-details-save-button,
  .event-details-sponsor-button {
    font-size: 14px;
  }

  .social-media-sponsor-icons a,
  .social-media-sponsor-icons p {
    font-size: 30px;
  }

  .social-media-sponsor-icons .malievents-email {
    font-size: 20px;
  }

  .event-displayer-profile-image,
  .event-displayer-event-image {
    max-width: 80%; /* Adjust the width to your preference */
    height: auto;
    margin-left: 10%; /* Add margin to move the images to the right */
  }

  .event-displayer-dates,
  .event-displayer-address {
    font-size: 14px;
  }
  .heart-icon{
    margin-left: 150px;
  }
  .share-icon{
    margin-left: 150px;
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .event-details-delete-button,
  .event-details-share-button,
  .event-details-save-button,
  .event-details-sponsor-button {
    font-size: 12px;
    width: 50%;
    margin-left: 80px;
  }

  .social-media-sponsor-icons a,
  .social-media-sponsor-icons p {
    font-size: 24px;
  }

  .social-media-sponsor-icons .malievents-email {
    font-size: 18px;
  }

  .event-displayer-dates,
  .event-displayer-address {
    font-size: 12px;
  }

  /* New styles for even smaller screens */
  .event-displayer-profile-image,
  .event-displayer-event-image {
    max-width: 70%; /* Adjust the width to your preference */
    height: auto;
    margin-left: 15%; /* Add more margin to move the images further right */
  }
  .heart-icon{
    margin-left: 150px;
  }
  .share-icon{
    margin-left: 150px;
  }
}


/* ... (Existing styles for other sections) */

.sponsors-section {
  margin-top: 20px;
}
.sponsors-section h1{
  color: red;
}

.sponsors-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sponsor-logo {
  width: 100px; /* Adjust the width of the logos as needed */
  height: 100px; /* Adjust the height of the logos as needed */
  margin: 10px; /* Adjust the spacing between logos as needed */
}

/* ... (Other styles as needed) */
