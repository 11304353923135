.required-field {
    color: red;
}

.form-button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
}

/* Styles for larger screens */
@media screen and (min-width: 768px) {
  .form-input,
  .form-button {
    width: 80%;
  }
}

/* Styles for medium-sized screens */
@media screen and (max-width: 480px) and (min-width: 375px) {
  .form-input,
  .form-button {
    width: 90%;
  }
}

/* Styles for iPhone 12 Pro */
@media screen and (max-width: 390px) {
  .form-input,
  .form-button {
    width: 100%;
  }
}

/* Additional adjustments for even smaller screens if needed */

/* For example, for screens with width <= 320px */
@media screen and (max-width: 320px) {
  .form-input,
  .form-button {
    width: 100%;
  }
}
  