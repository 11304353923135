.navbar {
    margin: 0;
    width: 100%;
    height: 80px;
    background-color: rgb(190, 228, 115);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    text-decoration: none;
  }
  
  .account-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    color: white;
  }
  
  .save-icon {
    width: 25px;
    height: 25px;
    margin-right: 10px; /* Adjust the margin to move the save icon closer to the person icon */
  }
  
  .create {
    color: white;
    margin-right: 50px;
  }
  
  .logout-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: red; /* Change the color to red */
    font-size: 23px; /* Increase the font size */
    padding: 8px 16px; /* Add padding to make the button bigger */
  }
  
  /* Increase the font size of the logo for larger screens */
  .logo {
    margin-top: 85px;
    font-size: 60px; /* Increase the font size */
    margin-right: 12px;
    margin-left: 20px; /* Adjust the margin to move the logo more to the left */
    cursor: pointer;
  }
  
  /* Decrease the size of the burger menu for smaller screens */
  .burger-menu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20px; /* Decrease the height for smaller screens */
    padding: 8px 10px; /* Adjust the padding for smaller screens */
    z-index: 1000; /* Ensure the burger menu is above the menu items */
  }
  
  .burger-line {
    width: 24px;
    height: 2px;
    background-color: white;
    transition: transform 0.3s;
  }
  
  .open .burger-line:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .open .burger-line:nth-child(2) {
    opacity: 0;
  }
  
  .open .burger-line:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  .menu-items {
    position: fixed;
    top: 0;
    left: 0;
    width: 33.3333vw; /* 1/3 of the screen width */
    height: 100vh;
    background-color: rgb(190, 228, 115);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
    overflow-x: hidden;
    transition: width 0.4s ease;
  }
  
  .open .menu-items {
    width: 33.3333vw; /* 1/3 of the screen width */
  }
  
  .menu-item {
    color: white;
    font-size: 20px;
    text-decoration: none;
    margin: 15px;
  }
  
  /* Responsive styles for smaller screens */
  @media screen and (max-width: 850px) {
    .about-us,
    .nos-services,
    .accueil {
      display: none;
    }
  
    /* Reduce font size for smaller screens */
    .logo {
      font-size: 20px !important;
      margin: 0px !important;
      margin-left: 20px !important;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  
    .account-icon {
      width: 15px;
      height: 15px;
      margin-top: 18px;
      margin-left: 10px;
    }
  
    .save-icon {
      width: 15px; /* Adjust the size for smaller screens */
      height: 15px; /* Adjust the size for smaller screens */
      margin-top: 18px;
      margin-left: 5px;
      margin-right: 5px; /* Adjust the margins for smaller screens */
    }
  
    .logout-button {
      font-size: 10px;
      padding: 5px 5px;
    }
  
    .navbar {
      margin: 0;
      width: 100%;
      height: 80px;
      background-color: rgb(190, 228, 115);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: white;
      text-decoration: none;
      text-align: center;
    }
    .navbar a {
      font-size: 11px !important;
    }
  
    /* Hide the regular Navbar links */
    .navbar-links {
      display: none;
    }
  
    .burger-menu {
      padding: 8px 10px;
    }
  
    .burger-line {
      width: 20px;
      height: 1.5px;
    }
  
    .menu-items {
      width: 50vw; /* 1/2 of the screen width */
    }
  }
  