.nos_services {
  font-size: 22px;
  line-height: 1.5;
}

.nos_services h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.nos_services h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.nos_services p {
  font-size: 19px;
  margin-bottom: 10px;
}


/* Responsive Styles */
@media (max-width: 768px) {
  .nos_services {
    font-size: 18px;
  }

  .nos_services h1 {
    font-size: 30px;
  }

  .nos_services h2 {
    font-size: 18px;
  }

  .nos_services p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .nos_services {
    font-size: 16px;
  }

  .nos_services h1 {
    font-size: 28px;
  }

  .nos_services h2 {
    font-size: 16px;
  }

  .nos_services p {
    font-size: 14px;
  }
}