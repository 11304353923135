.terms {
  font-size: 22px;
  line-height: 1.5;
  margin: 20px;
}

.terms h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.terms h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.terms p {
  font-size: 19px;
  margin-bottom: 10px;
}

.contract {
  font-size: 13px;
  color: red;
  margin-bottom: 30px;
}

.forbidden {
  color: red;
  font-size: 20px;
}

.terms-contact-us {
  color: green;
  font-size: 20px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .terms {
    font-size: 20px;
    margin: 15px;
  }

  .terms h1 {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .terms h2 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .terms p {
    font-size: 17px;
    margin-bottom: 8px;
  }

  .contract {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .forbidden {
    font-size: 16px;
  }

  .terms-contact-us {
    font-size: 18px;
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .terms {
    font-size: 18px;
    margin: 10px;
  }

  .terms h1 {
    font-size: 26px;
    margin-bottom: 12px;
  }

  .terms h2 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .terms p {
    font-size: 15px;
    margin-bottom: 6px;
  }

  .contract {
    font-size: 11px;
    margin-bottom: 15px;
  }

  .forbidden {
    font-size: 14px;
  }

  .terms-contact-us {
    font-size: 16px;
  }
}
