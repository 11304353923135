.auth {
    display: flex;
    flex-direction: column; /* Update to vertical layout */
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

.auth-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 400px;
}

.green {
    color: green;
}

.yellow {
    color: yellow;
}

.red {
    color: red;
}

.logo {
    font-size: 60px; /* Increase the font size */
    margin-bottom: 100px; /* Add margin bottom to create space between logo and form */
    cursor: pointer;
}

.create-account {
    font-size: 15px;
    color: black;
  }
  
.green-text {
    font-size: 15px;
    color: green;
}


/* Responsive Styles */

/* For screens with width <= 480px */
@media screen and (max-width: 480px) {
.auth-container {
    width: 90%; /* Reduce the width for small screens */
  }
  .logo {
    font-size: 40px; /* Reduce the font size for small screens */
    margin-bottom: 40px; /* Reduce the margin bottom for small screens */
  }
}

/* For screens with width <= 375px */
@media screen and (max-width: 375px) {
  .auth-container {
    width: 80%; /* Further reduce the width for smaller screens */
  }
  .logo {
    font-size: 30px; /* Reduce the font size even more for smaller screens */
    margin-bottom: 30px; /* Reduce the margin bottom even more for smaller screens */
  }
}
  
  /* For screens with width <= 320px */
@media screen and (max-width: 320px) {
    .auth-container {
      width: 70%; /* Reduce the width for the smallest screens */
    }
    .logo {
      font-size: 20px; /* Further reduce the font size for the smallest screens */
      margin-bottom: 20px; /* Further reduce the margin bottom for the smallest screens */
    }
}