.section__padding{
    padding: 4rem 4rem;
}

.footer {
    background-color: white;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  

.sb__footer{
    display: flex;
    flex-direction: column;
}
.sb__footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}
.sb__footer-links_div{
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: black;
}

a{
    color: rgb(175, 175, 179);
    text-decoration: none;
}

.social-media-icons {
    margin-top: 20px;
}
  
.social-media-icons a {
    display: inline-block;
    margin-right: 10px;
    color: #007bff;
    font-size: 24px;
    transition: color 0.3s ease-in-out;
}

.social-media-icons a:hover {
  color: #0056b3;
}

.sb__footer-links_div h4{
    font-size: 20px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}
.sb__footer-links_div p{
    font-size: 14px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
    color: black;
}

.sb__footer-bellow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb__footer-bellow-links{
    display: flex;
    flex-direction: row;
}

.sb__footer-bellow-links p{
    font-size: 14px;
    line-height: 15px;
    margin-left: 2rem;
    color: black;
    font-weight: 600;
}

.sb__footer-links_div a {
    margin: 0.2rem 0;
  }

hr{
    color: black !important;
    width: 100%;
}

.sb__footer-copyright p{
    font-size: 14px;
    line-height: 15px;
    color: black;
    font-weight: 600;
}

@media screen and (max-width: 850px){
    .sb__footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    }
}
@media screen and (max-width: 550px){
    .sb__footer-heading h1{
        font-size: 34px;
        line-height: 42px;
    }
    .sb__footer-links div{
        margin: 1rem 0;
    }

    .sb__footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }
    .sb__footer-bellow{
        flex-direction: column;
        justify-content: left;
    }
    .sb__footer-bellow-links{
        flex-direction: column;
    }
    .sb__footer-bellow-links p{
        margin-left: 0rem;
        margin-top: 1rem;
    }

}

@media screen and (max-width: 550px){
    .sb__footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
}