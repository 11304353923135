.how-to-use {
  font-size: 22px;
  line-height: 1.5;
}

.how-to-use h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.how-to-use h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.how-to-use p {
  font-size: 19px;
  margin-bottom: 10px;
}

.for-promotor {
  margin-top: 40px;
  margin-bottom: 100px; /* Add margin-bottom to create space between sections */
}

.for-companies {
  margin-top: 20px; /* Add margin-top to create space between sections */
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .how-to-use {
    font-size: 18px;
  }

  .how-to-use h1 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .how-to-use h2 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .how-to-use p {
    font-size: 17px;
    margin-bottom: 8px;
  }

  .for-promotor,
  .for-companies {
    margin: 20px 0; /* Reduce the margin on smaller screens */
  }
}

/* Media query for even smaller screens */
@media screen and (max-width: 480px) {
  .how-to-use {
    font-size: 16px;
  }

  .how-to-use h1 {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .how-to-use h2 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .how-to-use p {
    font-size: 15px;
    margin-bottom: 6px;
  }

  .for-promotor,
  .for-companies {
    margin: 15px 0; /* Reduce the margin on even smaller screens */
  }
}
