.about-us-container {
    text-align: center;
}

.image-section {
  margin-top: 20px;
}

.oumou-sangare {
  width: 80%;
  max-height: 400px;
  object-fit: cover;
}

.sections-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}

.left-sections {
  flex-basis: 50%;
  text-align: left;
  padding: 0 20px;
}

.right-sections {
  flex-basis: 50%;
  text-align: left;
  padding: 0 20px;
}

.about-us-container h4 {
  color: #333;
  font-size: 20px;
  margin-top: 30px;
}

.about-us-container p {
  color: #555;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .about-us-container h4 {
    font-size: 18px;
  }

  .about-us-container p {
    font-size: 14px;
  }

  .oumou-sangare {
    max-height: 300px;
  }

  .left-sections,
  .right-sections {
    flex-basis: 100%;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .about-us-container h4 {
    font-size: 16px;
  }

  .about-us-container p {
    font-size: 13px;
  }

  .oumou-sangare {
    max-height: 200px;
  }
}
