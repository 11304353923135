/* ContactUs.css */

.form-container {
    max-width: 400px;
    margin: 0 auto;
}

.form-input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
}

.form-textarea {
  display: block;
  width: 100%;
  height: 120px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
}

.form-button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-message {
  margin-top: 10px;
  font-size: 14px;
  color: #007bff;
}

.social-media-icons {
  margin-top: 20px;
}

.social-media-icons a {
  display: inline-block;
  margin-right: 10px;
  color: #007bff;
  font-size: 24px;
  transition: color 0.3s ease-in-out;
}

.social-media-icons a:hover {
  color: #0056b3;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-content .space{
  margin-right: 20px;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content div {
  margin-right: 30px;
  margin-bottom: 10px;
  color: #333;
}

.dropdown-content div:last-child {
  margin-bottom: 0;
}

.required-field {
    color: red;
}

.contact-container.dropdown-open {
    transform: translateY(80px);
}

/* Add the following media query styles to your ContactUs.css file */

/* Responsive Styles */
@media (max-width: 768px) {
  .form-container {
    max-width: 100%;
  }

  .form-input,
  .form-textarea {
    font-size: 14px;
  }

  .form-button {
    font-size: 14px;
  }

  .form-message {
    font-size: 12px;
  }

  .social-media-icons a {
    font-size: 20px;
  }

  .dropdown-content {
    padding: 10px;
    min-width: 120px;
  }

  .dropdown-content div {
    margin-right: 20px;
    margin-bottom: 5px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .form-input,
  .form-textarea {
    font-size: 12px;
  }

  .form-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .form-message {
    font-size: 10px;
  }

  .social-media-icons a {
    font-size: 16px;
  }

  .dropdown-content {
    padding: 8px;
    min-width: 100px;
  }

  .dropdown-content div {
    margin-right: 15px;
    margin-bottom: 5px;
    font-size: 12px;
  }
}

  