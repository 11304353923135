.create-event {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 400px;
}
.create-event form {
  display: flex;
  flex-direction: column;
}

.form-button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

.form-textarea {
    display: block;
    width: 100%;
    height: 120px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
}

.form-input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
}
.log-to-create{
  font-size: 15px;
  margin-top: 300px;
}
.log-to-create-green-text{
  font-size: 16px;
  color: green;
}

.log-to-create-form-button {
  margin-top: 80px;
  margin-bottom: 600px;
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.log-to-create-form-button:hover {
  background-color: #0056b3;
}

/* Styles for larger screens */
@media screen and (min-width: 768px) {
  .create-event {
    max-width: 600px;
  }
}

/* Styles for medium-sized screens */
@media screen and (max-width: 480px) and (min-width: 375px) {
  .create-event {
    max-width: 360px;
  }
}

/* Styles for iPhone 12 Pro */
@media screen and (max-width: 390px) {
  .create-event {
    max-width: 340px;
  }
}

/* Additional adjustments for even smaller screens if needed */

/* For example, for screens with width <= 320px */
@media screen and (max-width: 320px) {
  .create-event {
    max-width: 280px;
  }
}